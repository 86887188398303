import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108')
];

export const server_loads = [0,2,14,15,3,4,6,7,10];

export const dictionary = {
		"/(admin)": [~16,[2]],
		"/api/v1/orders/[id]/print": [86],
		"/api/v1/tickets/[id]/download": [87],
		"/api/v1/tickets/[id]/print": [88],
		"/(admin)/discounts": [~17,[2]],
		"/(admin)/floorplans": [18,[2]],
		"/(admin)/getting-started": [~19,[2]],
		"/global-office": [~89,[14]],
		"/global-office/accounting": [~90,[14]],
		"/global-office/seller-requests": [~91,[14]],
		"/global-office/seller-requests/[request]": [~92,[14]],
		"/login": [~93],
		"/logout": [~94],
		"/(admin)/misc/under-construction": [20],
		"/office": [~95,[15]],
		"/office/accounting": [~96,[15]],
		"/office/boxoffice": [~97,[15]],
		"/office/boxoffice/external-screen": [~98],
		"/office/cart": [~99,[15]],
		"/office/checkout": [~100,[15]],
		"/office/editions/[editionId]/note": [101,[15]],
		"/office/events/[eventId]": [~102,[15]],
		"/office/orders": [~103,[15]],
		"/office/orders/[order]": [~104,[15]],
		"/office/settings": [~105,[15]],
		"/office/turnover": [~106,[15]],
		"/scan": [~107],
		"/scan/pda": [~108],
		"/(admin)/series": [21,[2]],
		"/(admin)/series/[series]": [22,[2,3]],
		"/(admin)/series/[series]/discounts": [~23,[2,3]],
		"/(admin)/series/[series]/editions/[edition]": [~24,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/billing": [~25,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/billing/cs/f": [~26,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/discounts": [~27,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/events": [~28,[2,3,4,5]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]": [~29,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/capacities": [~30,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/discounts": [~31,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/floorplan": [~32,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/graphics": [33,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/notesForOffices": [~34,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/products": [~35,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/products/[product]/order": [~36,[2,3,4,5,6,7]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/products/[product]/settings": [~37,[2,3,4,5,6,7]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/settings": [~38,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics": [~39,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics/rules": [~40,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics/rules/[ticket]": [~41,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics/sales": [~42,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/statistics/scans": [~43,[2,3,4,5,6,8]],
		"/(admin)/series/[series]/editions/[edition]/events/[event]/tickets": [~44,[2,3,4,5,6]],
		"/(admin)/series/[series]/editions/[edition]/offices": [~45,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/offices/notes": [~46,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/orders": [~47,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/orders/[order]": [~48,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/sales": [~49,[2,3,4]],
		"/(admin)/series/[series]/editions/[edition]/sales/festival/[festival]": [~52,[2,3,4,10]],
		"/(admin)/series/[series]/editions/[edition]/sales/festival/[festival]/daily": [~53,[2,3,4,10]],
		"/(admin)/series/[series]/editions/[edition]/sales/festival/[festival]/history": [~54,[2,3,4,10]],
		"/(admin)/series/[series]/editions/[edition]/sales/(edition)/offices": [~50,[2,3,4,9]],
		"/(admin)/series/[series]/editions/[edition]/sales/(edition)/performance": [~51,[2,3,4,9]],
		"/(admin)/series/[series]/settings": [55,[2,3]],
		"/(admin)/settings": [56,[2,11]],
		"/(admin)/settings/about": [~57,[2,11]],
		"/(admin)/settings/access-policies": [~58,[2,11]],
		"/(admin)/settings/colors": [59,[2,11]],
		"/(admin)/settings/cs/f/live": [~60,[2,12]],
		"/(admin)/settings/cs/f/live/charts/by-day": [~61,[2,12,13]],
		"/(admin)/settings/cs/f/live/charts/sales": [~62,[2,12,13]],
		"/(admin)/settings/cs/f/live/charts/scans": [~63,[2,12,13]],
		"/(admin)/settings/cs/f/live/charts/today": [~64,[2,12,13]],
		"/(admin)/settings/cs/f/live/numbers": [~65,[2,12]],
		"/(admin)/settings/exhibitorportal": [~66,[2,11]],
		"/(admin)/settings/exhibitorportal/[exhibitor]": [~67,[2,11]],
		"/(admin)/settings/invoices": [~68,[2,11]],
		"/(admin)/settings/layouts": [~69,[2,11]],
		"/(admin)/settings/layouts/[layout]": [~70,[2]],
		"/(admin)/settings/limits": [~71,[2,11]],
		"/(admin)/settings/linked-events": [~72,[2,11]],
		"/(admin)/settings/logos": [~73,[2,11]],
		"/(admin)/settings/misc": [~74,[2,11]],
		"/(admin)/settings/offices": [~75,[2,11]],
		"/(admin)/settings/offices/[office]": [~76,[2,11]],
		"/(admin)/settings/offices/[office]/qr": [~77,[2,11]],
		"/(admin)/settings/roles": [~78,[2,11]],
		"/(admin)/settings/sponsors": [~79,[2,11]],
		"/(admin)/settings/stripe": [~80,[2,11]],
		"/(admin)/settings/stripe/cash-point-closings": [~81,[2,11]],
		"/(admin)/settings/users": [~83,[2,11]],
		"/(admin)/settings/users/[user]/qr": [~84,[2,11]],
		"/(admin)/settings/user": [82,[2,11]],
		"/(admin)/settings/venues": [~85,[2,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';